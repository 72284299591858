<template>
  <v-autocomplete
    :id="'autocomplete-' + tagLabel"
    :items="docs"
    :label="label"
    :loading="loading"
    v-model="item"
    item-value="_id"
    hide-details
    return-object
    :search-input.sync="searchInput"
    :rules="rules"
    no-filter
    :prepend-icon="
      edit ? 'mdi-account-edit' : link ? 'mdi-link-box-variant' : null
    "
    @click:prepend="edit ? $emit('edit') : link ? $emit('link') : null"
    outlined
    :dense="!mobile"
    clearable
    attach
    @change="$emit('change')"
    class="user-field"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="name-title">
          {{
            [item.firstName, item.lastName].filter(Boolean).join(' ') ||
            'No name registered'
          }}
          <v-chip
            v-if="isCurrentProfile(item)"
            color="primary"
            label
            small
            outlined
          >
            Current profile
          </v-chip>
        </v-list-item-title>
        <v-list-item-subtitle v-if="item.email">
          {{ item.email }}
        </v-list-item-subtitle>
        <v-list-item-subtitle> {{ getAddress(item) }} </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.memberId">
          Member ID: {{ item.memberId }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      {{
        [item.firstName, item.lastName].filter(Boolean).join(' ') || item.email
      }}
    </template>
    <template v-slot:no-data>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>Type to search</v-list-item-title>
          <v-list-item-subtitle v-if="addNew">
            If you don't find the requested user, please register him/her
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="addNew">
          <v-btn icon @click="$emit('add')">
            <v-icon color="primary">mdi-account-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:append-outer v-if="searchAction">
      <v-tooltip bottom v-if="item">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon @click="$emit('search')">
            <v-icon>mdi-card-search</v-icon>
          </v-btn>
        </template>
        <span>Click to recalculate milestones for the selected user</span>
      </v-tooltip>
    </template>
    <template v-slot:append-item="">
      <div v-intersect="endIntersect"></div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex';
import { getAddress, getUserInfo } from '../../helpers/handler';
export default {
  name: 'autocomplete-users',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Object, String],
      default: () => null
    },
    editable: {
      type: Boolean,
      default: () => false
    },
    addNew: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    virtuous: {
      type: Boolean,
      default: false
    },
    linkable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    docs: [],
    search: null,
    loading: false,
    tempName: null,
    userId: null,
    hasNextPage: false,
    page: 1
  }),
  mounted() {
    const user = getUserInfo();
    const { _id, id } = user || {};
    this.userId = _id || id;
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    edit() {
      return this.editable && !!(this.value?._id || this.value?.id);
    },
    link() {
      return this.linkable && !!(this.value?._id || this.value?.id);
    },
    searchAction() {
      return this.searchable && !!(this.value?._id || this.value?.id);
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (value) {
        if (value !== this.search) {
          this.search = value;
          value ? this.getUserList(value) : (this.docs = []);
        }
      }
    },
    item: {
      get: function () {
        return this.getItemValue();
      },
      set: function (item) {
        this.$emit('input', item);
      }
    },
    tagLabel() {
      return this.label.toLowerCase().replace(' ', '') || 'user';
    }
  },
  methods: {
    ...mapActions('users', ['getUsers', 'getVirtuous']),
    async getUserList(name = '') {
      this.page = 1;
      this.tempName = name;
      this.loading = true;
      const payload = { name, page: this.page, limit: 10 };
      let response;
      if (this.virtuous) {
        response = await this.getVirtuous(payload);
      } else {
        response = await this.getUsers(payload);
      }
      const { docs, hasNextPage } = response?.data || {};
      this.docs = docs || [];
      this.hasNextPage = hasNextPage;
      this.loading = false;
    },
    async getMoreUsers() {
      this.page++;
      this.loading = true;
      const payload = { name: this.tempName, page: this.page, limit: 10 };
      let response;
      if (this.virtuous) {
        response = await this.getVirtuous(payload);
      } else {
        response = await this.getUsers(payload);
      }
      const { docs, hasNextPage } = response?.data || {};
      this.docs = [...this.docs, ...(docs || [])];
      this.hasNextPage = hasNextPage;
      this.loading = false;
    },
    getAddress(user) {
      const address = getAddress(user);
      return address;
    },
    isCurrentProfile(user) {
      const { _id, id } = user || {};
      return _id === this.userId || id === this.userId;
    },
    getItemValue() {
      const { firstName, lastName, _id, id } = this.value || {};
      const name = [firstName, lastName].filter(Boolean).join(' ') || '';
      const userId = _id || id;

      if (userId?.includes('@')) {
        this.docs = [{ _id: userId, email: userId }];
        return userId;
      }

      if (!name) {
        return userId;
      }

      if (this.tempName === name && this.docs.length !== 1) {
        return userId;
      }

      if (userId) {
        const exist = this.docs.some((doc) => userId === doc._id);
        !exist && this.getUserList(name);
        return userId;
      }

      if (!this.docs.length) {
        this.getUserList(name);
        return null;
      }

      if (this.docs.length === 1) {
        const { _id, id } = this.docs[0];
        return _id || id;
      }

      return null;
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.docs && this.hasNextPage) {
        await this.getMoreUsers();
      }
    }
  }
};
</script>

<style>
.user-field div[class~='v-input__append-outer'] {
  display: contents;
}

.name-title {
  display: flex;
  justify-content: space-between;
}
</style>
